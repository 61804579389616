import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import features from './features.mjs'

export default defineAppConfig({
  cmsService: {
    tenant: 'PL',
    storeId: 3,
  },
  faq: {
    sendQuestionsToCallCenter: false,
  },
  advisory: {
    enabled: false,
  },
  ...features
})
