export default {
  footer: {
    links: [
      {
        url: '/kim-jestesmy',
        text: 'O nas',
      },
      {
        url: '/faq',
        text: 'Pomoc',
      },
      {
        url: '/bezpieczne-zakupy',
        text: 'Bezpieczeństwo',
      },
    ],
  },
}
